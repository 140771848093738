.swiper-pagination-wrapper {
    .pagination-arrows {
        figure {
            width: 62px;
            height: 62px;
            flex-shrink: 0;
            background-color: #FFF3ED;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                background-color: #FF4514;
                img {
                    filter: brightness(100);
                }
            }
            @media only screen and (max-width: 639px) {
                width: 46px;
                height: 46px;
            }
        }
    }
    .pagination-progress {
        max-width: 280px;
        width: 100%;
        span {
            font-size: 16px;
            color: #FF4514;
        }
        .progress {
            max-width: 222px;
            width: 100%;
            background-color: #E9DAD4;
            height: 1px;
            position: relative;
            .done {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 50%;
                background-color: #FF4514
            }
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
}